<div class="main-wrapper">
<ngx-dropzone class="converter-wrapper" (change)="onDrop($event)" disableClick [ngStyle]="{'display': (progress == 'start') ? 'block' : 'none'}">
    <div class="upload">
      <div class="bordered-area">
        <img src="../../../assets/GIFS/PDF-MAGNET-UPLOAD.gif" alt="">
        <div class="app-title">{{ content.appTitleText }}</div>
        <div class="app-guideline">{{ content.appGuidlineText }}</div>
      </div>
      <button type="button" (click)="onClick()" class="pdf-button standard-button choose-file-button">
        {{ content.chooseFilesButtonText }}
        <img class="choose-file-button-img" src="../../../assets/images/file-icon.svg">
      </button>
      <p class="lock-text">
        <img src="../../../assets/images/lock-icon.svg" />
       
        {{ content.lockText }}
      </p>
    </div>
    <input type="file" (change)="onChange()" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
  </ngx-dropzone>

  <div class="single-file" [ngStyle]="{'display': (progress == 'format') ? 'flex' : 'none'}">
    <div class="file-wrapper" *ngIf="files.length > 0">
      <img src="../../../assets/images/file-icon-black.svg">
      <div class="filename">{{ files[0].name }}</div>
    </div>
    <div class="buttons-wrapper">
      <div class="files-dropdown">
        <img class="select-arrow" src="../../../assets/images/select-arrow.svg">

        <mat-select 
        *ngIf="progress == 'format'" 
        (valueChange)="handleFormatChange($event)"
        [ngClass]="['pdf-button', 'pdf-button-md', 'select-bordered-button']" placeholder="Select format">
          <mat-option *ngFor="let format of outputFormats" [value]="format">
            {{format.toUpperCase()}}
          </mat-option>
        </mat-select>

      </div>
      <button type="button" class="pdf-button standard-button start-over-button pdf-button-md" (click)="clearScreen('start')">Start over</button>
    </div>
    <button type="button" class="pdf-button btn-convert pdf-button-md" [disabled]="!selectedFormat" (click)="uploadFile()">Convert</button>
  </div>
  
  <div class="pdf-progress" [ngStyle]="{'display': (progress == 'loading') ? 'block' : 'none'}">
  
      <div class="pdf-progress-status" #progressStatus>Converting...</div>
      <img src="../../../assets/GIFS/pdf-magnet-spinner.gif" alt="">
      <div class="pdf-cancel-progress" (click)="progress='cancel'">Cancel</div>
  </div>
  
  <div class="alert" [ngStyle]="{'display': (progress == 'cancel') ? 'block' : 'none'}">
    <div class="alert-container">
     <img src="../../../assets/images/warning.svg" >
      <div class="alert-text">Are you sure you want to <br>
         cancel conversion?</div>
      <div class="buttons-wrapper cancel-buttons">
        <button type="button" class=" pdf-button standard-button pdf-button-small" (click)="clearScreen('start')">Yes</button>
        <button type="button" class=" pdf-button standard-button pdf-button-small pdf-button-transparent" (click)="progress='loading'">No</button>
      </div>
    </div>
  </div>
  
  <div class="alert" [ngStyle]="{'display': (progress == 'error-multi') ? 'block' : 'none'}">
    <div class="alert-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60"><defs><style>.alert-icon{fill:#00d6ff;}</style></defs><path class="alert-icon" d="M32.41,17.48c0-1.37-1.22-2-2.43-2s-2.47.52-2.47,2c0,2.41.14,5.41.28,8.3s.29,5.92.29,8.35c0,1.12,1.19,1.29,1.9,1.29s1.86-.31,1.86-1.29c0-2.43.15-5.44.29-8.35S32.41,19.89,32.41,17.48Z"/><path class="alert-icon" d="M30,39.23a2.6,2.6,0,0,0-2.63,2.64A2.63,2.63,0,0,0,30,44.5a2.68,2.68,0,0,0,2.59-2.63A2.65,2.65,0,0,0,30,39.23Z"/><path class="alert-icon" d="M30,2A28,28,0,1,1,2,30,28,28,0,0,1,30,2m0-2A30,30,0,1,0,60,30,30,30,0,0,0,30,0Z"/></svg>
      <div class="alert-text">Uploading multiple files is not supported</div>
      <div class="buttons-wrapper">
        <button type="button" class="btn" (click)="clearScreen('start')">Start over</button>
      </div>
    </div>
  </div>
  
  <div class="alert" [ngStyle]="{'display': (progress == 'error-occurred') ? 'block' : 'none'}">
    <div class="alert-container">
      <img src="../../../assets/images/warning-red.svg" >
      <div class="alert-text">
        Error occurred
        <div class="alert-subtext">Please, try again</div>
      </div>
      
      <div class="buttons-wrapper error-alert-buttons">
        <button type="button" class="pdf-button standard-button" (click)="clearScreen('start')">Start over</button>
      </div>
    </div>
  </div>
  
  <div class="alert" [ngStyle]="{'display': (progress == 'error-alert') ? 'block' : 'none'}">
    <div class="alert-container">
      <img src="../../../assets/images/warning-red.svg" >
      <div class="alert-text">This file type you have <br> selected is not supported</div>
      
      <div class="buttons-wrapper error-alert-buttons">
        <button type="button" class="pdf-button standard-button" (click)="clearScreen('start')">Start over</button>
      </div>
    </div>
  </div>
  
  <div class="download" [ngStyle]="{'display': (progress == 'finished') ? 'flex' : 'none'}">
    <img src="../../../assets/GIFS/pdf-magnet-done-gif.gif" /> 
    <div class="download-headline">Your file has been
      downloaded</div>
    <div class="download-success">
      if you do not see the download, <span class="try-again" (click)="clearScreen('start')">try again.</span>
    </div>
    <button type="button" class="pdf-button standard-button" (click)="clearScreen('start')">Start over</button>
  </div>
</div>