import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from '@app/components/app/app.component';
import { PdfComponent } from '@app/components/pdf/pdf.component';
import { UploadService } from '@services/upload.service';
import { HttpClientModule } from '@angular/common/http';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from '@app/components/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {MatSelectModule} from '@angular/material/select';
import { AdsenseModule } from 'ng2-adsense';

@NgModule({
  declarations: [
    AppComponent,
    PdfComponent,
    HeaderComponent,
  ],
  imports: [
    AdsenseModule.forRoot({
      adClient: 'ca-pub-5094956578837515'
    }),
    BrowserModule,
    HttpClientModule,
    NgxDropzoneModule,
    AppRoutingModule,
    CookieModule.forRoot(),
    NgbModule,
    NoopAnimationsModule,
    MatSelectModule
  ],
  providers: [UploadService],
  bootstrap: [AppComponent]
})
export class AppModule { }
