import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from  '@angular/common/http';
import { catchError } from 'rxjs/operators'; 
import { environment } from '@environments/environment'
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private httpClient: HttpClient) {}

  initFormats(ext:string) {
    return this.httpClient
      .get<any>(environment.service_url + environment.path.pdf + ext)
      .pipe(catchError(this.handleError));
  }

  caseConvert(file: File, format: string) {
    const response = {error: null, subscribtion: null, rejected: []};
    response.subscribtion = this.convertFile(file, format)
    return response
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage:string;

    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //return throwError(errorMessage);
    return errorMessage
  }

  private convertFile(file:File, type: string) {
    let formData: FormData = new FormData();
    formData.append(`file`, file, file.name);

    return this.httpClient
    .post<any>(environment.service_url + environment.path.convert + type, formData)
    .pipe(catchError(this.handleError));
  }
}