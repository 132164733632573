import { Injectable } from '@angular/core';
import { RefWindowService } from '../ref-window.service';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GtmDataLayerService {
   private window; 

   constructor (private _windowRef: RefWindowService)
   {
       this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service

   }

    private pushDataLayer(obj) {
        //console.log(obj)
        if(obj && !environment.extension)  this.window.dataLayer.push(obj);
    }
   
   logPageView(url)
   {
       const hit = {
           event: 'content-view',
           pageName: url
       };
       this.pushDataLayer(hit);
   }

   logEvent(event, category, action, label)
   {
       const hit = {
           event:event,
           eventCategory:category,
           eventAction:action,
           eventLabel: label
       }
        this.pushDataLayer(hit);
   }
}