import { Component, ViewChild, ElementRef } from '@angular/core';
import { UploadService } from  '@services/upload.service';
import { CookieService } from 'ngx-cookie';
import { Subscription } from 'rxjs';
import { GtmDataLayerService } from '../../services/gtm-data-layer.service';
import { Router, NavigationEnd } from '@angular/router';
import { ModalComponent } from '../modal/modal.component';
@Component({
  selector: 'type-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent {
  content = {
    appTitleText: 'Drag & Drop File Here',
    appGuidlineText: 'or browse on your device',
    chooseFilesButtonText: 'Choose File',
    spinnerMessageText: 'Converting...',
    successMessageText: 'We have successfully converted your file!',
    lockText: 'Your files are secure'
  }
  private _progress:string = 'start'
  private _selectedFormat:string;
  private progress_list = ['start','loading', 'format', 'cancel','error-multi','error-occurred','error-alert','finished']
  outputFormats = [];
  inProgress:boolean = false;
  files  = []
  sub: Array<Subscription> = [];
  lp_id = null;
  intervalRef = [];
  @ViewChild('progressStatus', {static: false}) progressStatus: ElementRef
  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;
  @ViewChild('progressLabel', { static: false }) progressLabel: ElementRef
  @ViewChild('progressTooltip', { static: false }) progressTooltip: ElementRef
  @ViewChild('progressBar', { static: false }) progressBar: ElementRef
  
  constructor(
    private uploadService: UploadService,
    private gtmDataLayerService: GtmDataLayerService,
    private cookieService: CookieService,
    private router:Router) {
      this.router.events.subscribe(event=> {
        if (event instanceof NavigationEnd) {
          this.gtmDataLayerService.logPageView(event.url)
        }
      });
      
  }

  uploadFile() {
    this.progress = 'loading'

      const response = this.uploadService.caseConvert(this.files[0], this.selectedFormat);
      if(response.error) {
        this.clearScreen(response.error)
      } else {
        this.gtmDataLayerService.logEvent('ga_event','convert','convert', '');
        const s = response.subscribtion.subscribe(result => {
          if(this.progress == 'loading') {
            console.log(result)
            if (result['success'] == true) {
              this.downloadFile(result)
            } else {
              this.clearScreen('error-occurred')
            }
          }
        })
        this.sub.push(s)
      }
  }

  //remove PDF from list
  removeItem(item:string, arr:Array<string>) {
    const index = arr.indexOf(item);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr
  }

  selectFormat() {
    if(this.files.length === 1) {
      const ext = this.files[0].name.split('.').pop().toLowerCase();
      const response = this.uploadService.initFormats(ext);

      const s = response.subscribe(result => {
        if (result['success'] == true) {
          console.log("ext", ext);
          console.log("result", result);
          if(ext == "pdf") {
            this.progress = 'format'
            this.outputFormats = this.removeItem(ext, result['formats']); //remove PDF from list
          } else if(result['formats'].includes('pdf')) {
            this.progress = 'format'
            this.outputFormats.push("pdf");
          } else {
            this.clearScreen('error-alert')
          }
        } else {
          this.clearScreen('error-occurred')
        }
      })
      this.sub.push(s)
    } else {
      this.clearScreen('error-multi')
    }
  }

  clearScreen(progress:string) {
    if(progress && this.progress_list.includes(progress)) {
      this.progress = progress
    }

    Object.values(this.sub).forEach((value:Subscription, index)=> {
      value.unsubscribe()
    })

    this.sub = []
    this.files = [];
    this.outputFormats = [];
    this.inProgress = false;
    this.selectedFormat = null;
    this.fileUpload.nativeElement.value = null;
    
    var elems = document.querySelectorAll(".single-file .btn-convert");
    for (var i = 0; i < elems.length; i++) {
      elems[i].classList.add('btn-disabled');
    }
    
    while (this.intervalRef.length) {
      // console.log('interval clear');
      this.intervalRef.forEach(element => clearInterval(element));
      this.intervalRef.pop()
    }
    this.inProgress = false;
  }

  set selectedFormat(val: string) {
    this._selectedFormat = val;
    if(val != "Select format") {
      var elems = document.querySelectorAll(".single-file .btn-convert");

      for (var i = 0; i < elems.length; i++) {
          elems[i].classList.remove('btn-disabled');
      }
    }
  }

  get selectedFormat(): string {
    return this._selectedFormat;
  }

  set progress(val: string) {
    this._progress = val;
  }

  get progress(): string {
    return this._progress;
  }

  private downloadFile(file) {
    const inIFrame = (window.location != window.parent.location);
    if (inIFrame) {
      window.parent.postMessage(
        {
          event_id: 'conversionComplete',
          params: {
            url: file["file"]["url"],
            name: file["file"]["filename"]
          }
        },
        "*"
      );
    }
    else {
      window.location = file["file"]["url"];
    }
    this.files = []
    this.progress = 'finished'
    //completion event can be enter here
  }

  loading() {
    // progress bar for display only
    this.inProgress = true;
    let ref;
    let width = 0;
    let progressBar = this.progressBar.nativeElement;
    let progressLabel = this.progressLabel.nativeElement;
    let progressStatus = this.progressStatus.nativeElement;
    let progressTooltip = this.progressTooltip.nativeElement;
    ref = setInterval(function () {
      if (width == 100) {
        clearInterval(ref)
        progressStatus.innerHTML = 'Downloading...';
      }
      else {
        width++;
        progressBar.style.width = width + "%";
        progressLabel.innerHTML = width + "%";
        const tooltipAdjustment = width > 10 ? width - 10  : 0 
        progressTooltip.style.left = tooltipAdjustment + "%";
        progressTooltip.innerHTML = width + "%";
      }
    }, 100);
    this.intervalRef.push(ref)
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.click();
    this.gtmDataLayerService.logEvent('ga_event','click','Choose a file', '');
  }

  onChange() {
    const fileUpload = this.fileUpload.nativeElement;

    if(fileUpload.files.length > 0) {
      this.files.push(...fileUpload.files);
      this.selectFormat();
    }
  }

  onDrop(event) {
    if(event.addedFiles) {
      if(event.addedFiles.length > 0) {
        this.files.push(...event.addedFiles);
        this.selectFormat();
        this.gtmDataLayerService.logEvent('ga_event','drop','Choose a file', '');
      }
    }
  }

  getCookie(key: string){
    return this.cookieService.get(key);
  }

  handleFormatChange(format) {
    this.selectedFormat = format;
  }
}