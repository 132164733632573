import { Injectable } from '@angular/core';

function getRefWindow():any {
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class RefWindowService {
    get nativeWindow() {
        return getRefWindow();
    }
}